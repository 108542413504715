import axios from "axios";
import importLambda from "services/import-lambda";

export const uploadCourtesies = async (selectedFile, event_id) => {
    const companyName =
        process.env.REACT_APP_WHITELABEL === "example-ticket"
            ? "superticket-test"
            : process.env.REACT_APP_WHITELABEL;
    try {
        const {
            data: {
                data: { uploadUrl, key },
            },
        } = await importLambda.get(
            `get-upload-url?fileName=${selectedFile.name}&companyName=${companyName}`
        );

        await axios.put(uploadUrl, selectedFile, {
            headers: {
                "Content-Type":
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
        });

        const startEcsBody = {
            key,
            companyName,
            eventId: event_id,
        };
        await importLambda.post("start-ecs", startEcsBody);
        return {
            success: true,
            error: undefined,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
};