import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Form } from "react-bootstrap";
import FormField from "components/FormField";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ButtonContained from "components/buttonContained";
import { ReactComponent as LinkIcon } from "../../../assets/icons/link-icon.svg";
import { ReactComponent as Sync } from "../../../assets/icons/Synchronize.svg";
import CopyButton from "components/copyButton";
import { useTranslation } from "react-i18next";

const MIN_LENGTH = 3;

const MIN_LENGTH_MESSAGE = `O campo deve conter no mínimo ${MIN_LENGTH} caracteres.`;

const analyticsSchema = yup.object({
  analyticsCode: yup
    .string()
    .trim()
    .min(MIN_LENGTH, MIN_LENGTH_MESSAGE)
    .required("O código do google analytics é obrigatório."),
});

const metaSchema = yup.object({
  metaPixelId: yup
    .string()
    .trim()
    .min(MIN_LENGTH, MIN_LENGTH_MESSAGE)
    .required("O pixel do meta é obrigatório.")
    .nullable(),
  metaCodeVerifier: yup.string().nullable(),
});

const adsSchema = yup.object({
  adsConversionId: yup
    .string()
    .trim()
    .min(MIN_LENGTH, MIN_LENGTH_MESSAGE)
    .required("O Id de conversão é obrigatório.")
    .nullable(),
  adsConversionSnippet: yup
    .string()
    .trim()
    .min(MIN_LENGTH, MIN_LENGTH_MESSAGE)
    .required("O Snippet de conversão é obrigatório."),
});

const publicApiSchema = yup.object({
  apiToken: yup.string(),
  buyersUrl: yup.string(),
  checkinUrl: yup.string(),
});

const integrationSchemas = {
  ads: adsSchema,
  analytics: analyticsSchema,
  meta: metaSchema,
  publicApi: publicApiSchema,
};

ConfigurationModalContent.propTypes = {
  type: PropTypes.oneOf(["ads", "analytics", "meta", "publicApi"]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  integration: PropTypes.object.isRequired,
};

export default function ConfigurationModalContent({
  type,
  onSubmit,
  onClose,
  expired,
  onUpdateToken,
  integration,
}) {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      analyticsCode: "",
      metaPixelId: "",
      metaCodeVerifier: "",
      adsConversionId: "",
      adsConversionSnippet: "",
      apiToken: "",
      buyersUrl: "",
      checkinUrl: "",
    },
    mode: "all",
    resolver: yupResolver(integrationSchemas[type]),
  });

  const { t } = useTranslation("integrations");

  const expireDate = new Date(integration[type].infos?.expire_date);

  const token = integration[type].infos?.token;

  useEffect(() => {
    return () => {
      reset();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (type === "analytics") {
      setValue("analyticsCode", integration[type].infos?.g_analytics_code);
    } else if (type === "ads") {
      setValue("adsConversionId", integration[type].infos?.g_id_ads);
      setValue(
        "adsConversionSnippet",
        integration[type].infos?.g_conversion_snippet_ads
      );
    } else if (type === "meta") {
      setValue("metaPixelId", integration[type].infos?.fb_pixel_id);
      setValue(
        "metaCodeVerifier",
        integration[type]?.infos?.fb_domain_verification_code
      );
    }
  }, [integration, setValue, type]);

  return (
    <div className="integration-body-container">
      <div className="integration-body-title">
        {type === "publicApi"
          ? t("modal.publicApi.publicTokenLabel")
          : t("common.configureIntegration")}
      </div>
      {type === "publicApi" && (
        <p className="m-0 mb-3 p-0 integration-label-info">
          {t("modal.publicApiDesc")}
        </p>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        {type === "analytics" ? (
          <Fragment>
            <Form.Row>
              <Col md="12">
                <FormField
                  classNameLabel="new-label"
                  label={t("modal.analytics.analyticsLabel")}
                  errorMessage={errors.analyticsCode?.message}
                  controlId="validationAnalyticsCode"
                >
                  <Form.Control
                    {...register("analyticsCode")}
                    name="analyticsCode"
                    type="text"
                    placeholder={t("modal.analytics.analyticsCode")}
                    isInvalid={errors.analyticsCode?.message}
                  />
                </FormField>
              </Col>
            </Form.Row>
          </Fragment>
        ) : type === "meta" ? (
          <Fragment>
            <Form.Row>
              <Col md="12">
                <FormField
                  classNameLabel="new-label"
                  label="Pixel ID"
                  errorMessage={errors.metaPixelId?.message}
                  controlId="validationMetaPixelId"
                >
                  <Form.Control
                    {...register("metaPixelId")}
                    name="metaPixelId"
                    type="text"
                    placeholder={t("modal.meta.pixelId")}
                    isInvalid={errors.metaPixelId?.message}
                  />
                </FormField>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md="12">
                <FormField
                  classNameLabel="new-label"
                  label={t("modal.meta.codeVerifierLabel")}
                  errorMessage={errors.metaCodeVerifier?.message}
                  controlId="validationMetaCodeVerifier"
                >
                  <Form.Control
                    {...register("metaCodeVerifier")}
                    name="metaCodeVerifier"
                    type="text"
                    placeholder={t("modal.meta.codeVerifier")}
                    isInvalid={errors.metaCodeVerifier?.message}
                  />
                </FormField>
              </Col>
            </Form.Row>
          </Fragment>
        ) : type === "ads" ? (
          <Fragment>
            <Form.Row>
              <Col md="12">
                <FormField
                  classNameLabel="new-label"
                  label={t("modal.ads.conversionId")}
                  errorMessage={errors.adsConversionId?.message}
                  controlId="validationAdsConversionId"
                >
                  <Form.Control
                    {...register("adsConversionId")}
                    name="adsConversionId"
                    type="text"
                    placeholder={t("modal.ads.conversionIdPlace")}
                    isInvalid={errors.adsConversionId?.message}
                  />
                </FormField>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md="12">
                <FormField
                  classNameLabel="new-label"
                  label={t("modal.ads.snippet")}
                  errorMessage={errors.adsConversionSnippet?.message}
                  controlId="validationAdsConversionSnippet"
                >
                  <Form.Control
                    {...register("adsConversionSnippet")}
                    name="adsConversionSnippet"
                    type="text"
                    placeholder={t("modal.ads.snippetPlace")}
                    isInvalid={errors.adsConversionSnippet?.message}
                  />
                </FormField>
              </Col>
            </Form.Row>
          </Fragment>
        ) : (
          type === "publicApi" && (
            <Fragment>
              {token && (
                <Fragment>
                  <div>
                    <Form.Row>
                      <FormField
                        classNameLabel="new-label pl-1"
                        classNameGroup="mb-0"
                        label={
                          <span>
                            API Token
                            <p className="integration-label-info">
                              {t("modal.publicApi.publicTokenText")}
                            </p>
                          </span>
                        }
                        errorMessage={errors.apiToken?.message}
                        controlId="validationApiToken"
                      />
                      <Col md="9">
                        <div className="container-link">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="f-flex justify-content-start text-truncate">
                              <span>{token}</span>
                            </div>

                            <div className="d-flex justify-content-end">
                              <CopyButton
                                type="button"
                                content={integration[type].infos?.token}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="3" className="mt-1 mt-md-0">
                        <ButtonContained
                          id="update-token-button"
                          className="secondary-outlined"
                          type="button"
                          onClick={onUpdateToken}
                          content={
                            <Fragment>
                              {t("common.update")} <Sync />
                            </Fragment>
                          }
                        />
                      </Col>
                      <Col md="12">
                        <p
                          className={`valid-information-text ${
                            expired && "expired"
                          }`}
                        >
                          {expired
                            ? `${t(
                                "modal.publicApi.tokenExpired"
                              )} ${expireDate.toLocaleDateString("pt-Br")}. ${t(
                                "modal.publicApi.needsUpdate"
                              )}`
                            : `${t(
                                "modal.publicApi.valid"
                              )} ${expireDate.toLocaleDateString("pt-Br")}`}
                        </p>
                      </Col>
                    </Form.Row>
                  </div>
                  <Form.Row>
                    <Col md="12">
                      <FormField
                        classNameLabel="new-label"
                        label={
                          <span>
                            {t("modal.publicApi.buyersUrlLabel")}
                            <p className="integration-label-info">
                              {t("modal.publicApi.urlTextDescription")}
                            </p>
                          </span>
                        }
                        controlId="validationBuyersUrl"
                      >
                        <div className="container-link">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="f-flex justify-content-start">
                              <LinkIcon width={24} height={24} />
                              <span className="ml-2">
                                {integration[type].infos?.buyer_url}
                              </span>
                            </div>

                            <div className="d-flex justify-content-end">
                              <CopyButton
                                type="button"
                                content={integration[type].infos?.buyer_url}
                              />
                            </div>
                          </div>
                        </div>
                      </FormField>
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col md="12">
                      <FormField
                        classNameLabel="new-label mt-0"
                        label={
                          <span>
                            {t("modal.publicApi.checkinUrlLabel")}
                            <p className="integration-label-info">
                              {t("modal.publicApi.urlTextDescription")}
                            </p>
                          </span>
                        }
                        controlId="validationCheckinUrl"
                      >
                        <div className="container-link">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="f-flex justify-content-start">
                              <LinkIcon width={24} height={24} />
                              <span className="ml-2">
                                {integration[type].infos?.checkin_url}
                              </span>
                            </div>

                            <div className="d-flex justify-content-end">
                              <CopyButton
                                type="button"
                                content={integration[type].infos?.checkin_url}
                              />
                            </div>
                          </div>
                        </div>
                      </FormField>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col md="12">
                      <FormField
                        classNameLabel="new-label mt-0"
                        label={
                          <span>
                            {t("modal.publicApi.ticketsUrlLabel")}

                            <p className="integration-label-info">
                              {t("modal.publicApi.urlTextDescription")}
                            </p>
                          </span>
                        }
                        controlId="validationCheckinUrl"
                      >
                        <div className="container-link">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="f-flex justify-content-start">
                              <LinkIcon width={24} height={24} />

                              <span className="ml-2">
                                {integration[type].infos?.ticket_url}
                              </span>
                            </div>

                            <div className="d-flex justify-content-end">
                              <CopyButton
                                type="button"
                                content={integration[type].infos?.ticket_url}
                              />
                            </div>
                          </div>
                        </div>
                      </FormField>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col md="12">
                      <FormField
                        classNameLabel="new-label mt-0"
                        label={
                          <span>
                            {t("modal.publicApi.participantsUrlLabel")}

                            <p className="integration-label-info">
                              {t("modal.publicApi.urlTextDescription")}
                            </p>
                          </span>
                        }
                        controlId="validationCheckinUrl"
                      >
                        <div className="container-link">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="f-flex justify-content-start">
                              <LinkIcon width={24} height={24} />

                              <span className="ml-2">
                                {integration[type].infos?.participant_url}
                              </span>
                            </div>

                            <div className="d-flex justify-content-end">
                              <CopyButton
                                type="button"
                                content={integration[type].infos?.participant_url}
                              />
                            </div>
                          </div>
                        </div>
                      </FormField>
                    </Col>
                  </Form.Row>
                </Fragment>
              )}
            </Fragment>
          )
        )}

        <div className="d-flex justify-content-end">
          <div className="col-md-3 col-4 pr-0">
            {!token ? (
              <Fragment>
                <ButtonContained
                  id="generate-token-button"
                  className="primary-contained"
                  type="submit"
                  content={
                    !token && type === "publicApi"
                      ? t("common.makeToken")
                      : t("common.save")
                  }
                />
              </Fragment>
            ) : (
              <Fragment>
                <ButtonContained
                  id="close-token-modal-button"
                  className="secondary-outlined"
                  type="button"
                  onClick={onClose}
                  content={<Fragment>{t("common.close")}</Fragment>}
                />
              </Fragment>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
}
